import $ from 'jquery';

const ready = function() {
  var objectPhotoWidth = $('.objects-container').width();
  var timelineWidth = $('.timeline-range').width();
  var timelineStart = 2004;
  var timelineEnd = 2023;
  var timelineStep = timelineWidth / (timelineEnd - timelineStart + 1);

  var startingValue = $('chekhov').data('start') - timelineStart;
  var endingValue = $('chekhov').data('end') - timelineStart + 1;
  $('.timeline-range-indicator').css({
    left: timelineStep * startingValue + 'px',
    width: timelineStep * (endingValue - startingValue) + 'px',
  });

  $('.dot').mouseover(function(event) {
    $('.dot').removeClass('active');
    $(this).addClass('active');
    var index = $(this).index();

    var startingValue = $(this).data('start') - timelineStart;
    var endingValue = $(this).data('end') - timelineStart + 1;

    $('.timeline-range-indicator').css({
      left: timelineStep * startingValue + 'px',
      width: timelineStep * (endingValue - startingValue) + 'px',
    });
  });
};

$(document).ready(ready);

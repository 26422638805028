import $ from 'jquery';
import Plyr from 'plyr';

$(() => {
  $('.plyr-player').each((index, element) => {
    new Plyr(element, {
      disableContextMenu: true,
      hideControls: true,
      controls: ['play-large', 'progress', 'current-time', 'fullscreen'],
    });
  });
});

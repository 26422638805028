import $ from 'jquery';

const ready = function() {
  if (window.scrollY > 0) {
    setBgPosition();
  }

  function setBgPosition() {
    const pageHeight = $('body').height();
    const currentOffset = window.scrollY;
    const delta = currentOffset / pageHeight;
    $('.park-bg-image').css('background-position-y', delta * 100 + '%');
    $('.park-bg-image').css('background-position-x', delta * 100 + '%');
  }

  const intervalId = setInterval(setBgPosition, 10);
};

$(document).ready(ready);

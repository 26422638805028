import $ from 'jquery';

/* eslint-disable */
const MOBILE_WIDTH = 767;

const ready = function() {
  let gCurrentSidepageId = '';

  function showSidepage() {
    if ($('#' + gCurrentSidepageId).length) {
      $('.js-sidepage-open').removeClass('active');
      $('[data-id="' + gCurrentSidepageId + '"]').addClass('active');

      $('.sidepage').removeClass('active');
      $('#' + gCurrentSidepageId).addClass('active');
      $('.sidepage-bg').addClass('visible');

      setNextSidepageTitle();

      if (window.innerWidth < MOBILE_WIDTH) {
        $('body').addClass('overflow-hidden');
      }
      $('.sidepage-scroll').scrollTop(0);
    }
  }

  function closeSidepage() {
    $('.sidepage-bg').removeClass('visible');
    $('.sidepage').removeClass('active');
    $('.js-sidepage-open').removeClass('active');
    if (window.innerWidth < MOBILE_WIDTH) {
      $('body').removeClass('overflow-hidden');
    }
    gCurrentSidepageId = null;
  }

  function showPrevSidepage() {
    let $prevSidepageTarget = $('[data-id="' + gCurrentSidepageId + '"]')
      .prev()
      .first();

    if (!$prevSidepageTarget.length) {
      $prevSidepageTarget = $('.js-sidepage-open').last();
    }
    gCurrentSidepageId = $prevSidepageTarget.data('id');
    showSidepage();
  }
  function showNextSidepage() {
    let $nextSidepageTarget = $('[data-id="' + gCurrentSidepageId + '"]')
      .next()
      .first();
    if (!$nextSidepageTarget.length) {
      $nextSidepageTarget = $('.js-sidepage-open').first();
    }

    gCurrentSidepageId = $nextSidepageTarget.data('id');
    showSidepage();
  }
  function setNextSidepageTitle() {
    let $nextSidepageTarget = $('[data-id="' + gCurrentSidepageId + '"]')
      .next()
      .first();
    if (!$nextSidepageTarget.length) {
      $nextSidepageTarget = $('.js-sidepage-open').first();
    }
    let title = $nextSidepageTarget.find('span').html();
    let id = $nextSidepageTarget.data('id');
    $('#' + gCurrentSidepageId)
      .find('.promo-link')
      .html(title + ' →');
    console.log($('#' + gCurrentSidepageId).find('.promo-link'));
    $('#' + gCurrentSidepageId)
      .find('.promo-link')
      .data('id', id);

    console.log('title: ' + title + ' id: ' + id);
  }

  $('.js-sidepage-open').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    gCurrentSidepageId = $(this).data('id');
    showSidepage();
  });

  $('.js-sidepage-close').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    closeSidepage();
  });

  // $('.js-sidepage-prev').click(function(e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   showPrevSidepage();
  // });

  // $('.js-sidepage-next').click(function(e) {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   showNextSidepage();
  // });

  $('.js-open-next').click(function(e) {
    gCurrentSidepageId = $(this).data('id');
    console.log('id: ' + gCurrentSidepageId);
    e.preventDefault();
    e.stopPropagation();
    showSidepage();
  });

  $('body').click(function(e) {
    if (
      gCurrentSidepageId &&
      ($(e.target).closest('.sidepage').length ||
        $(e.target).closest('.pswp').length)
    )
      return;

    if (gCurrentSidepageId && $(e.target).hasClass('js-sidepage-open')) {
      gCurrentSidepageId = $(e.target).data('id');
      showSidepage();
    } else if (gCurrentSidepageId) {
      closeSidepage();
    }
  });

  $(document).keydown(function(e) {
    if (e.keyCode == 27 && gCurrentSidepageId) {
      closeSidepage();
    } else if (e.keyCode == 37 && gCurrentSidepageId) {
      showPrevSidepage();
    } else if (e.keyCode == 39 && gCurrentSidepageId) {
      showNextSidepage();
    }
  });
};

$(document).ready(ready);

import $ from 'jquery';

$('.js-scroll-link').click(function(e) {
  e.preventDefault();

  var linkOffset = -20;
  if ($($.attr(this, 'href')).data('scroll-link-offset')) {
    linkOffset += $($.attr(this, 'href')).data('scroll-link-offset');
  }

  $('html, body').animate(
    {
      scrollTop: $($.attr(this, 'href')).offset().top + linkOffset,
    },
    500
  );
});

import $ from 'jquery';

const ready = function() {
  // var activeApartmentPopup = 0;

  $('.ostrovskiy-plans-scroll-container').animate(
    {
      scrollLeft: 1000,
    },
    20000
  );

  $('.ostrovskiy-plans-scroll-container').on('touchstart', function() {
    $('.ostrovskiy-plans-scroll-container').stop();
  });

  var popupsCount = $('.apartment-popup').length;
  $('.apartment-popup').each(function(index) {
    $(this).attr('name', index);
    console.log(index);
  });

  $('.js-open-pop').click(function(e) {
    e.preventDefault();
    $('.apartment-popups').addClass('visible');
    $('.apartment-popup').removeClass('active');
    $('#' + this.name).addClass('active');
    $('.ostrovskiy-caption-6').hide();
  });

  $('.js-popup-close').click(function(e) {
    e.preventDefault();
    $('.apartment-popups').removeClass('visible');
  });

  $('.js-popup-next').click(function(e) {
    e.preventDefault();
    showNextPopup();
  });

  $('.js-popup-prev').click(function(e) {
    e.preventDefault();
    showPrevPopup();
  });

  $('.js-popup-compare').click(function(e) {
    e.preventDefault();
    $('.apartment-popup').removeClass('comparing');
    $(this)
      .closest('.apartment-popup')
      .addClass('comparing');
    $('.apartment-popups').addClass('comparison');
    showNextPopup();
  });

  function showNextPopup() {
    var activeIndex = $('.apartment-popup.active').attr('name');
    $('.apartment-popup[name="' + activeIndex + '"]').removeClass('active');
    var newActiveIndex = activeIndex - 0 + 1;
    console.log(newActiveIndex);
    if (newActiveIndex == popupsCount) {
      newActiveIndex = 0;
    }
    $('.apartment-popup[name="' + newActiveIndex + '"]').addClass('active');
  }

  function showPrevPopup() {
    var activeIndex = $('.apartment-popup.active').attr('name');
    $('.apartment-popup[name="' + activeIndex + '"]').removeClass('active');
    var newActiveIndex = activeIndex - 1;
    if (newActiveIndex < 0) {
      newActiveIndex = popupsCount - 1;
    }
    console.log(newActiveIndex);
    $('.apartment-popup[name="' + newActiveIndex + '"]').addClass('active');
  }

  $('.js-popup-uncompare').click(function(e) {
    e.preventDefault();
    $(this)
      .closest('.apartment-popup')
      .removeClass('comparing');
    $('.apartment-popups').removeClass('comparison');
  });

  $('.js-comparing-link').click(function(e) {
    e.preventDefault();
    $('.apartment-popups').addClass('visible');
  });

  $('.js-comparing-link').hover(
    function() {
      $(this)
        .closest('.apartment-popup')
        .addClass('icon-hover');
    },
    function() {
      $(this)
        .closest('.apartment-popup')
        .removeClass('icon-hover');
    }
  );
};

$(document).ready(ready);
